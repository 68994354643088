import Vue from "vue";
import VueI18n from "vue-i18n";
import axios from "@/plugins/axios.plugin";

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: "us",
  fallbackLocale: "us",
});

export const loadLanguageAsync = async () => {
  return axios.get(`/settings/public/translations`).then((response) => {
    const msgs = response.data.setting_value;

    // for (const languageKey of Object.keys(msgs)) {
    //   if (languageKey == selectedLanguages[0]) {
    //     i18n.locale = languageKey
    //     i18n.fallbackLocale = languageKey
    //   }
    // }

    for (const [locale, messages] of Object.entries(msgs)) {
      i18n.setLocaleMessage(locale, messages as any);
    }
  });
};
